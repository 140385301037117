@import "./colors";

.react-Rmodule-wrapper {
    height: inherit;
}

.Rmodule {
    min-height: 100%;
    margin: 0 2.5rem;
    padding: 5rem 0;
    background-color: white;
    font-family: 'Anonymous Pro', monospace;
    isolation: isolate;
}

.img-1-Rmodule {
    width: 30%;
    max-width: 430px;
    position: absolute;
    top: 8rem;
    left: 0;
}

.img-2-Rmodule {
    width: 25vw;
    max-width: 400px;
    position: relative;
    left: calc(100% - 25vw + 2.5rem);
}

.img-3-Rmodule {
    height: 0;
}

.Rmodule-info {
    width: 65%;
    margin: auto;
    margin-right: 2.5rem;
    h1 {
        text-align: left;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
    }
}

.Rmodule-outline {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 2rem;
    width: auto;
    max-width: 100%;
    margin: 0 2.5rem;
    text-align: left;
    h2 {
        grid-column: span 2;
        margin: 0.5rem 0;
        text-align: center;
    }
    div {
        padding-left: 1rem;
    }
    div:first-of-type {
        grid-column: 1;
    }
    div:last-child {
        grid-column: 2;
    }
    h3 {
        font-size: 1.5rem;
        margin: 0;
        margin-top: -1rem;
        &::before {
            content: "";
            position: relative;
            top: 1.15rem;
            left: -1rem;
            display: block;
            width: 0.65rem;
            height: 0.65rem;
            border-radius: 50%;
            background-color: $lightgreen;
            
        }
    }
    ol {
        list-style-type: upper-roman;
        font-family: 'Roboto', sans-serif;
        padding-left: 1.3rem;
    }
}

@media (max-width: 1100px) {
    .img-2-Rmodule {
        width: 32vw;
        max-width: 400px;
        position: relative;
        left: calc(100% - 32vw + 2.5rem);
        top: -1.5rem;
        z-index: -1;
    }

    .Rmodule-outline {
        margin-top: -1.5rem;
        h3 {
            font-size: 1.25rem;
            &::before {
                top: 0.95rem;
                left: -1rem;
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
            }
        }
    }
}

@media (max-width: 952px) {
    .img-2-Rmodule {
        height: 0;
    }

    .Rmodule-outline {
        margin-top: 13%;
    }
}

@media (max-width: 770px) {
    .img-1-Rmodule {
        height: 0;
        width: 0;
    }

    .img-3-Rmodule {
        height: auto;
        width: 90%;
        position: relative;
        left: -2.5rem;
        top: 1.5rem;
    }

    .Rmodule-info {
        width: auto;
        margin: 0 2.5rem;
        h1 {
            text-align: center;
        }
    }

    .Rmodule-outline {
        gap: 0;
        margin-top: 0%;
        display: flex;
        flex-direction: column;
        h2 {
            margin-bottom: 1.5rem;
            margin-top: 2.5rem;
        }
    }

    .Rmodule-info, .Rmodule-outline {
        margin: 0 1rem;
    }
}

@media (max-width:380px) {
    
    .Rmodule {
        display: flex;
        flex-direction: column;
        h1 {
            margin-top: 1.5rem;
        }
        h3 {
            font-size: 1rem;
            &::before {
                top: 0.75rem;
                left: -1rem;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
            }
        }
        ol {
            font-size: 0.85rem;
        }
    }

    .img-3-Rmodule {
       top: 1rem
    }
}

@media (max-width:318px) {
    
    .Rmodule {
        margin: 0 1.5rem;
        padding-bottom: 2rem;
        h1 {
            text-align: center;
            margin-top: 0;
        }
        h2 {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        img {
            display: none;
        }
        p {
            margin-bottom: 0.5rem;
        }
    }
}
