@import "./colors";



footer {
    position: relative;
    background-color: $darkblue;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: minmax(2.5rem, auto);
}

.footer-list {
    margin:0;
    margin-left: 2.5rem;
    grid-column: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding:0;
    li {
        list-style: none;
        font-family: 'Anonymous Pro', monospace;
        font-weight: 400;
        font-size: 1.125rem;
        letter-spacing: -0.02em;
        color: white;
        margin: 2.25rem 0;
        &::after {
            content: '';
            display: block;
            width: 0%;
            height: 3px;
            border-radius: 2px;
            background: $lightblue;
            transition: width .3s;
        }
        &:hover::after {
            width: 100%;
        }
    }
}

.copyright-div {
    margin: 0;
    margin-right: 2.5rem;
    grid-column: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright {
    font-family: 'Anonymous Pro', monospace;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}

.apiculae-link {
    color: inherit;
    transition: color 0.35s ease-in-out;
    &:hover {
        color: rgba(255, 255, 255, 1);
        transition: color 0.35s ease-in;
    }
}

@media (max-width: 825px){
    footer {
        display: flex;
        flex-direction: column;
    }

    .footer-list {
        flex-wrap: wrap;
        margin: 1rem 0;
        justify-content: space-evenly;
        li {
            margin: 0.5rem 1.5rem
        }
    }

    .copyright-div { 
        margin: 1rem;
    }
}