@import "./colors";

.react-about-wrapper {
    height: 100%;
}

.about-grid {
    min-height: 100%;
    margin: 0 2.5rem;
    padding: 5rem 0;
    background-color: white;
    font-family: 'Anonymous Pro', monospace;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    isolation: isolate;
}


.img-1-about {
    grid-column: 1;
    position: relative;
    top: 1.5rem;
    left: -2.5rem;
    width: 14vw;
    z-index: -1;
    max-width: 230px;
}


.img-2-about {
    grid-column: 3;
    position: absolute;
    top: 0;
    right: 0;
    width: 45vw;
    max-width: 750px;
}

.about-flex {
    // grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 65vw;
}

.about-info {
    width: 75%;
    padding: 0;
    margin: 0;
    h1 {
        text-align: left;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
    }
    p {
        margin-bottom: 2rem;
    }
}

.author-info {
    width: 100%;
    h2 {
        text-align: left;
        margin: 1rem 0;
        margin-left: 0;
        isolation: isolate;
        &::after {
            content: "";
            position: relative;
            display: block;
            left: -1.5rem;
            top: -1.6rem;
            width: 1rem;
            height: 1rem;
            background-color: $orange;
            border-radius: 50%;

            //decreasing bullet size as the font size decreases (stated in home scss)
            @media (max-width: 1100px) {
                width: 0.75rem;
                height: 0.75rem;
                left: -1.25rem;
                top: -1.25rem
            }
            @media (max-width: 952px) {
                width: 0.5rem;
                height: 0.5rem;
                left: -1rem;
                top: -0.85rem
            }
        }
    }
    p {
        margin-top: 0;
        margin-bottom: 1.5rem;
    }    
}


@media (max-width: 770px) {
    .about-grid {
        grid-template-columns: 18vw 1fr;
        @media (max-width:600px) {
            grid-template-columns: 15vw 1fr
        }
    }

    .img-1-about {
        width: 20vw;
        top: 1rem;
        @media (max-width:600px) {
            width: 21vw;
        }
        @media (max-width:500px) {
            width: 22.5vw;
        }
    }

    .img-2-about {
        height: 0;
    }

    .about-flex {
        width: 100%;
    }

    .about-info {
        h1 {
            margin-top: 1.5rem;
        }
    }

    .about-info, .author-info {
        width: auto;
        margin-right: 1rem;
    }
}

@media (max-width:380px) {
    
    .about-grid {
        grid-template-columns: 1fr;
        padding-bottom: 2rem;
        @media (max-width: 318px) {
            margin: 0 1.5rem;
        }
        section {
            margin-left: 1rem;
        }
        h1 {
            text-align: center;
            margin-top: 0;
        }
        h2 {
            margin-bottom: 0.5rem;
            margin-left: 1rem;
        }
        img {
            display: none;
        }
        p {
            margin-bottom: 0.5rem;
        }
    }
}
