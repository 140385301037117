@import "./colors";

.react-blog-wrapper {
    height: inherit;
}

.blog {
    min-height: 100%;
    margin: 0 2.5rem;
    padding: 5rem 0;
    padding-bottom: 0;
    background-color: white;
    font-family: 'Anonymous Pro', monospace;
    isolation: isolate;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
        text-align: center;
        margin: 2.5rem 1.5rem;
    }
}

.img-1-blog {
    width: 60%;
    position: relative;
    bottom: 0;
    left: -2.5rem;
} 


@media (max-width: 825px){
    .img-1-blog {
        width: 100%;
    }
}

@media (max-width:318px) {
    
    .blog {
        margin: 0 1.5rem;
        padding-bottom: 2rem;
        h1 {
            text-align: center;
            margin-top: 0;
        }
        img {
            display: none;
        }
    }
}