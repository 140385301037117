//colors
$dark-blue: #22205f;
$blue: #3e66af;
$lightblue: #6acadd;
$green: #015453;
$lightgreen: #3eba92;
$yellow: #fdd301;
$orange: #e46625;

html {
    background-color: $blue;
    font-family: 'Anonymous Pro', monospace;
    font-family: 'Roboto', sans-serif;
    height: 100%;
}

#root {
    height: 100%;
}

.react-home-wrapper {
    height: inherit;
}

body {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: space-between;
}

.home {
    min-height: 100%;
    margin: 0 2.5rem;
    padding: 5rem 0;
    background-color: white;
    font-family: 'Anonymous Pro', monospace;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    isolation: isolate;
}

section {
    width: 100%;
    text-align: center;
    font-weight: 400;
    letter-spacing: -0.02em;
}

h1 {
   font-size: 4rem;
   font-weight: 400;
}

h2, h3 {
    font-size: 2.5rem;
    font-weight: 400;
}

p {
    // font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    text-align: justify;
}

button {
    font-family: 'Anonymous Pro', monospace;
    font-weight: 700;
    font-size: 1.125rem;
    color: white;
    background-color: $lightgreen;
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.625rem;
    transition: background-color 0.35s ease-out;
    cursor: pointer;
    &:hover {
        background-color: $green;
        transition: background-color 0.35s ease-in;
    }
}

.main-row-1 {
    grid-column: 2;
    grid-row: 1;
    h1, h2 {
        padding: 0 4rem;
        margin: 1rem 0;
    }
    h1 {
        margin-top: 2.5rem;
    }
}

.image-1 {
    grid-column: 1;
    grid-row: 1;
    width: 110%;
    position: relative;
    left:-2.5rem;
    top: 2.5rem;
}

.main-row-2 {
    grid-column: 1;
    grid-row: 2;
    p, h3 {
        padding-left: 2.5rem;
        margin: 1rem 0;
    }
}

.image-2 {
    grid-column: 2;
    grid-row: 2;
    width: 60%;
    position: absolute;
    top: 23rem;
    right: 0;
    z-index: -1;
    @media (max-width: 1340px) {
        top: 26rem;
    }
}

.main-row-3 {
    grid-column: 2;
    grid-row: 3;
    p, h3 {
        padding-right: 2.5rem;
        margin: 1rem 0;
    }
    h3 {
        margin-top: 35%;
    }
}

.image-3 {
    grid-column: 1;
    grid-row: 3;
    width: 80%;
    position: relative;
    left:-2.5rem;
    top: 1.5rem;
}

.main-row-4 {
    grid-column: 1;
    grid-row: 4;
    p, h3 {
        padding-left: 2.5rem;
        margin: 1rem 0;
    }

    h3 {
        padding-top: 10%;
    }
}

.image-4 {
    grid-column: 2;
    grid-row: 4;
    width: 50%;
    position: absolute;
    top: 100rem;
    right: 1rem;
}

@media  (max-width: 1100px) {
    h1{
        font-size: 3rem;
    }
    
    h2, h3 {
        font-size: 2rem;
    }
    
    p {
        font-size: 1.25rem;
    }
    
    .image-2 {
        top: 22rem;
    }

    .image-3 {
        top: 1rem;
    }

    .image-4 {
        top: 85rem;
    }
}

@media (max-width: 952px) {
    h1 {
        font-size: 2.5rem;
    }
    h2, h3 {
        font-size: 1.5rem;
    }
    p {
       font-family: 'Roboto', sans-serif;
       font-size: 1rem; 
    }
    button {
        font-size: 1rem;
        padding: 0.75rem 1.75rem;
    }

    .image-2 {
        top: 20rem;
    }

    .image-3 {
        top: 0.75rem
    }

    .image-4 {
        top: 70rem
    }
}

@media (max-width: 770px) {
    .home {
        display: block;
    }

    .main-row-1, .main-row-2, .main-row-3, .main-row-4 {
        p, h3 {
        padding: 0 1rem;
        margin: 0;
        }
        h3 {
            padding-bottom: 1.25rem;
        }
        p {
            padding-bottom: 1rem;
        }
    }

    .main-row-2 h3 {
        margin-top: 36rem;
        @media (max-width:620px) {
            margin-top: 30rem;
        }
        // @media (max-width:435px) {
        //     margin-top: 21rem;
        // }
    }

    .image-1 {
        width: 105%;
    }

    .image-2 {
        position: absolute;
        width: 110%;
        right: 0;
        top: 45rem;
        @media (max-width:620px) {
            top: 42.5rem;
        }
    }
    
    .image-3 {
        height: 0;
    }

    .image-4 {
        position: relative;
        width: 100%;
        right: -1.25rem;
        top:0;
        margin: 0.5rem 0;
    }
}

@media (max-width:555px) {
    .image-1 {
        top: 1rem;
    }

    h1 {
        font-size: 2.25rem;
    }

    .main-row-1 {
        h1, h2 {
            padding: 0 2rem;
        }
        h1 {
            margin-top: 1rem;
        }
    }

    .image-2 {
       top: 39rem;
    }

    .main-row-2 h3 {
            margin-top: 28rem;
    }
}

@media (max-width:470px) {
    .image-1 {
        width: 110%;
    }

    .main-row-1 h1 {
        margin-top: 0;
    }

    .image-2  {
        width: 120%;
        top: 37rem;
    }

    .main-row-2 h3 {
        margin-top: 22rem;
        
    }
}

@media (max-width:380px) {
    
    .image-1 {
        width: 113%;
    }
    
    h1 {
        font-size: 2rem;
        text-align: center;
    }
    h2 {
        font-size: 1.25rem;
    }

    h3 {
        font-size: 1.25rem;
    }
    p {
        font-size: 0.85rem;
    }

    .image-2 {
        top: 32rem
    }

    .main-row-2 h3 {
        margin-top: 20rem;
    }

    button {
        font-size: 0.875rem;
        padding: 0.5rem 1.5rem;
    }
}

@media (max-width:318px) {

    .home {
        margin: 0 1.5rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        img {
            display: none;
        }
    }
    
    .main-row-2 h3 {
        margin-top: 2.5rem;
    }

}