@import "./colors";

// body {
//     background-color: $blue;
//     font-family: 'Anonymous Pro', monospace;
//     font-family: 'Roboto', sans-serif;
// }

nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1; 
    &::before{
        content: '';
        position: absolute;
        inset: 0;
        background-color: $yellow;
        width: 100%;
        height: 100%;
        transform: translateY(0.3rem);
        z-index: -2;
    }
}

.navbar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    grid-auto-rows: minmax(2.5rem, auto);
    background-color: #fff;
    &::before {
        //yellow triangle on the left
        content: '';
        position: absolute;
        height: 0;
        border-bottom: 5rem solid white;
        border-left: 5rem solid $yellow;
        width: 0;
        transform: translateY(0);
        z-index: 1;
    }
    &::after {
         //yellow triangle on the right
        content: '';
        position: absolute;
        height: 0;
        right: 0;
        border-top: 5rem solid white;
        border-right: 5rem solid $yellow;
        width: 0;
        z-index: 1;
    }
}

.nav-column-1 {
    grid-column: 1;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-right: auto;
}

.logo {
    padding-left: 5rem;
    height: 5rem;
}

.nav-column-2, .closed-nav {
    grid-column: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav-list {
    font-family: 'Anonymous Pro', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    letter-spacing: -0.02em;
    list-style: none;
    display: flex;
    flex-direction: row;
    padding-right: 5.7rem;
    li {
        margin-left: 2.8rem;
        &::after {
            content: '';
            display: block;
            width: 0%;
            height: 3px;
            border-radius: 2px;
            background: $orange;
            transition: width .3s;
        }
        &:hover::after {
            width: 100%;
        }
    }
}

a {
    text-decoration: none;
    color: inherit;
}

//Reset ::before in burger element//

.burger-div::before {
    content: none;
}

.burger-div div::before {
    content: none;
}

// Media Queries

@media (max-width: 825px){
    .navbar {
        display: flex;
        flex-direction: column;
        &::before {
            //triangle left
            border-bottom: 4rem solid white;
            border-left: 4rem solid $yellow;
        }
        &::after {
            //triangle right
            border-top: 4rem solid white;
            border-right: 4rem solid $yellow;
        }
    }

    .nav-column-1 {
        margin: auto;
        background-color: #fff;
        &::before {
            content:none
        }
    }

    .logo {
        margin: auto;
        padding: 0.25rem 0;
        height: 3.5rem;
    }

    .nav-column-2 {
        margin:0;
        background-color: $yellow;
        justify-content: left;
        max-height: 254px;
        transition: max-height 1s;
        overflow: hidden;
    }

    .nav-list {
        flex-direction: column;
        margin-bottom: 0;
        transform: translateY(0);
        transition: transform 1s;
        li {
            margin-bottom: 1.125rem;

            }
    }

    .closed-nav {
        background-color: $yellow;
        justify-content: left;
        max-height: 0;
        transition: max-height 1s;
        overflow: hidden;
        .nav-list {
            transform: translateY(-254px);
            transition: transform 1s ease-out;
        }
    }

    .burger-div {
        position: absolute;
        right: 0;
        height: 4rem;
        width: 4rem;
        z-index: 3;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        div {
            background-color: $lightgreen;
            width: 60%;
            height: 0.35rem;
            border-radius: 10px;
            z-index: 3;
            transition-property: transform, background-color, width;
            transition-duration: 0.4s;
            &:first-child{
                margin-top: 1rem;
            }
            &:last-child{
                margin-bottom: 1rem;
            }
        }
    }
    
    .line-2 {
        position: absolute;
        transform: translateY(-0.0625rem);
    }

    .clicked-line-1 {
        transform: rotate(110deg) translateX(0.8rem);
        width: 50% !important;
        background-color: $orange !important;
        transition-property: transform, background-color, width;
        transition-duration: 0.4s;
    }

    .clicked-line-4 {
        transform: rotate(110deg) translateY(-0.15rem) translateX(-0.8rem);
        background-color: $orange !important;
        width: 50% !important;
        transition-property: transform, background-color, width;
        transition-duration: 0.4s;
    }

    .clicked-line-2 {
        transform: translateY(-0.1rem) translateX(0.20rem);
        background-color: $orange !important;
        width: 50% !important;
        transition-property: transform, background-color, width;
        transition-duration: 0.4s;
    }

    .clicked-line-3 {
        transform: translateY(+0.15rem);
        background-color: $orange !important;
        width: 50% !important;
        transition-property: transform, background-color, width;
        transition-duration: 0.4s;
    }
}