nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

nav::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: #fdd301;
  width: 100%;
  height: 100%;
  -webkit-transform: translateY(0.3rem);
          transform: translateY(0.3rem);
  z-index: -2;
}

.navbar {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  grid-auto-rows: minmax(2.5rem, auto);
  background-color: #fff;
}

.navbar::before {
  content: '';
  position: absolute;
  height: 0;
  border-bottom: 5rem solid white;
  border-left: 5rem solid #fdd301;
  width: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  z-index: 1;
}

.navbar::after {
  content: '';
  position: absolute;
  height: 0;
  right: 0;
  border-top: 5rem solid white;
  border-right: 5rem solid #fdd301;
  width: 0;
  z-index: 1;
}

.nav-column-1 {
  -ms-grid-column: 1;
  grid-column: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: auto;
}

.logo {
  padding-left: 5rem;
  height: 5rem;
}

.nav-column-2, .closed-nav {
  -ms-grid-column: 2;
  grid-column: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav-list {
  font-family: 'Anonymous Pro', monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-right: 5.7rem;
}

.nav-list li {
  margin-left: 2.8rem;
}

.nav-list li::after {
  content: '';
  display: block;
  width: 0%;
  height: 3px;
  border-radius: 2px;
  background: #e46625;
  -webkit-transition: width .3s;
  transition: width .3s;
}

.nav-list li:hover::after {
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.burger-div::before {
  content: none;
}

.burger-div div::before {
  content: none;
}

@media (max-width: 825px) {
  .navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .navbar::before {
    border-bottom: 4rem solid white;
    border-left: 4rem solid #fdd301;
  }
  .navbar::after {
    border-top: 4rem solid white;
    border-right: 4rem solid #fdd301;
  }
  .nav-column-1 {
    margin: auto;
    background-color: #fff;
  }
  .nav-column-1::before {
    content: none;
  }
  .logo {
    margin: auto;
    padding: 0.25rem 0;
    height: 3.5rem;
  }
  .nav-column-2 {
    margin: 0;
    background-color: #fdd301;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    max-height: 254px;
    -webkit-transition: max-height 1s;
    transition: max-height 1s;
    overflow: hidden;
  }
  .nav-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
  }
  .nav-list li {
    margin-bottom: 1.125rem;
  }
  .closed-nav {
    background-color: #fdd301;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    max-height: 0;
    -webkit-transition: max-height 1s;
    transition: max-height 1s;
    overflow: hidden;
  }
  .closed-nav .nav-list {
    -webkit-transform: translateY(-254px);
            transform: translateY(-254px);
    -webkit-transition: -webkit-transform 1s ease-out;
    transition: -webkit-transform 1s ease-out;
    transition: transform 1s ease-out;
    transition: transform 1s ease-out, -webkit-transform 1s ease-out;
  }
  .burger-div {
    position: absolute;
    right: 0;
    height: 4rem;
    width: 4rem;
    z-index: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .burger-div div {
    background-color: #3eba92;
    width: 60%;
    height: 0.35rem;
    border-radius: 10px;
    z-index: 3;
    -webkit-transition-property: background-color, width, -webkit-transform;
    transition-property: background-color, width, -webkit-transform;
    transition-property: transform, background-color, width;
    transition-property: transform, background-color, width, -webkit-transform;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
  }
  .burger-div div:first-child {
    margin-top: 1rem;
  }
  .burger-div div:last-child {
    margin-bottom: 1rem;
  }
  .line-2 {
    position: absolute;
    -webkit-transform: translateY(-0.0625rem);
            transform: translateY(-0.0625rem);
  }
  .clicked-line-1 {
    -webkit-transform: rotate(110deg) translateX(0.8rem);
            transform: rotate(110deg) translateX(0.8rem);
    width: 50% !important;
    background-color: #e46625 !important;
    -webkit-transition-property: background-color, width, -webkit-transform;
    transition-property: background-color, width, -webkit-transform;
    transition-property: transform, background-color, width;
    transition-property: transform, background-color, width, -webkit-transform;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
  }
  .clicked-line-4 {
    -webkit-transform: rotate(110deg) translateY(-0.15rem) translateX(-0.8rem);
            transform: rotate(110deg) translateY(-0.15rem) translateX(-0.8rem);
    background-color: #e46625 !important;
    width: 50% !important;
    -webkit-transition-property: background-color, width, -webkit-transform;
    transition-property: background-color, width, -webkit-transform;
    transition-property: transform, background-color, width;
    transition-property: transform, background-color, width, -webkit-transform;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
  }
  .clicked-line-2 {
    -webkit-transform: translateY(-0.1rem) translateX(0.2rem);
            transform: translateY(-0.1rem) translateX(0.2rem);
    background-color: #e46625 !important;
    width: 50% !important;
    -webkit-transition-property: background-color, width, -webkit-transform;
    transition-property: background-color, width, -webkit-transform;
    transition-property: transform, background-color, width;
    transition-property: transform, background-color, width, -webkit-transform;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
  }
  .clicked-line-3 {
    -webkit-transform: translateY(0.15rem);
            transform: translateY(0.15rem);
    background-color: #e46625 !important;
    width: 50% !important;
    -webkit-transition-property: background-color, width, -webkit-transform;
    transition-property: background-color, width, -webkit-transform;
    transition-property: transform, background-color, width;
    transition-property: transform, background-color, width, -webkit-transform;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
  }
}

html {
  background-color: #3e66af;
  font-family: 'Anonymous Pro', monospace;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

.react-home-wrapper {
  height: inherit;
}

body {
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: inherit;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.home {
  min-height: 100%;
  margin: 0 2.5rem;
  padding: 5rem 0;
  background-color: white;
  font-family: 'Anonymous Pro', monospace;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  grid-auto-rows: minmax(auto, auto);
  isolation: isolate;
}

section {
  width: 100%;
  text-align: center;
  font-weight: 400;
  letter-spacing: -0.02em;
}

h1 {
  font-size: 4rem;
  font-weight: 400;
}

h2, h3 {
  font-size: 2.5rem;
  font-weight: 400;
}

p {
  font-size: 1.5rem;
  text-align: justify;
}

button {
  font-family: 'Anonymous Pro', monospace;
  font-weight: 700;
  font-size: 1.125rem;
  color: white;
  background-color: #3eba92;
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.625rem;
  -webkit-transition: background-color 0.35s ease-out;
  transition: background-color 0.35s ease-out;
  cursor: pointer;
}

button:hover {
  background-color: #015453;
  -webkit-transition: background-color 0.35s ease-in;
  transition: background-color 0.35s ease-in;
}

.main-row-1 {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
}

.main-row-1 h1, .main-row-1 h2 {
  padding: 0 4rem;
  margin: 1rem 0;
}

.main-row-1 h1 {
  margin-top: 2.5rem;
}

.image-1 {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  width: 110%;
  position: relative;
  left: -2.5rem;
  top: 2.5rem;
}

.main-row-2 {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}

.main-row-2 p, .main-row-2 h3 {
  padding-left: 2.5rem;
  margin: 1rem 0;
}

.image-2 {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 2;
  grid-row: 2;
  width: 60%;
  position: absolute;
  top: 23rem;
  right: 0;
  z-index: -1;
}

@media (max-width: 1340px) {
  .image-2 {
    top: 26rem;
  }
}

.main-row-3 {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 3;
  grid-row: 3;
}

.main-row-3 p, .main-row-3 h3 {
  padding-right: 2.5rem;
  margin: 1rem 0;
}

.main-row-3 h3 {
  margin-top: 35%;
}

.image-3 {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 3;
  grid-row: 3;
  width: 80%;
  position: relative;
  left: -2.5rem;
  top: 1.5rem;
}

.main-row-4 {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 4;
  grid-row: 4;
}

.main-row-4 p, .main-row-4 h3 {
  padding-left: 2.5rem;
  margin: 1rem 0;
}

.main-row-4 h3 {
  padding-top: 10%;
}

.image-4 {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 4;
  grid-row: 4;
  width: 50%;
  position: absolute;
  top: 100rem;
  right: 1rem;
}

@media (max-width: 1100px) {
  h1 {
    font-size: 3rem;
  }
  h2, h3 {
    font-size: 2rem;
  }
  p {
    font-size: 1.25rem;
  }
  .image-2 {
    top: 22rem;
  }
  .image-3 {
    top: 1rem;
  }
  .image-4 {
    top: 85rem;
  }
}

@media (max-width: 952px) {
  h1 {
    font-size: 2.5rem;
  }
  h2, h3 {
    font-size: 1.5rem;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
  }
  button {
    font-size: 1rem;
    padding: 0.75rem 1.75rem;
  }
  .image-2 {
    top: 20rem;
  }
  .image-3 {
    top: 0.75rem;
  }
  .image-4 {
    top: 70rem;
  }
}

@media (max-width: 770px) {
  .home {
    display: block;
  }
  .main-row-1 p, .main-row-1 h3, .main-row-2 p, .main-row-2 h3, .main-row-3 p, .main-row-3 h3, .main-row-4 p, .main-row-4 h3 {
    padding: 0 1rem;
    margin: 0;
  }
  .main-row-1 h3, .main-row-2 h3, .main-row-3 h3, .main-row-4 h3 {
    padding-bottom: 1.25rem;
  }
  .main-row-1 p, .main-row-2 p, .main-row-3 p, .main-row-4 p {
    padding-bottom: 1rem;
  }
  .main-row-2 h3 {
    margin-top: 36rem;
  }
}

@media (max-width: 770px) and (max-width: 620px) {
  .main-row-2 h3 {
    margin-top: 30rem;
  }
}

@media (max-width: 770px) {
  .image-1 {
    width: 105%;
  }
  .image-2 {
    position: absolute;
    width: 110%;
    right: 0;
    top: 45rem;
  }
}

@media (max-width: 770px) and (max-width: 620px) {
  .image-2 {
    top: 42.5rem;
  }
}

@media (max-width: 770px) {
  .image-3 {
    height: 0;
  }
  .image-4 {
    position: relative;
    width: 100%;
    right: -1.25rem;
    top: 0;
    margin: 0.5rem 0;
  }
}

@media (max-width: 555px) {
  .image-1 {
    top: 1rem;
  }
  h1 {
    font-size: 2.25rem;
  }
  .main-row-1 h1, .main-row-1 h2 {
    padding: 0 2rem;
  }
  .main-row-1 h1 {
    margin-top: 1rem;
  }
  .image-2 {
    top: 39rem;
  }
  .main-row-2 h3 {
    margin-top: 28rem;
  }
}

@media (max-width: 470px) {
  .image-1 {
    width: 110%;
  }
  .main-row-1 h1 {
    margin-top: 0;
  }
  .image-2 {
    width: 120%;
    top: 37rem;
  }
  .main-row-2 h3 {
    margin-top: 22rem;
  }
}

@media (max-width: 380px) {
  .image-1 {
    width: 113%;
  }
  h1 {
    font-size: 2rem;
    text-align: center;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  p {
    font-size: 0.85rem;
  }
  .image-2 {
    top: 32rem;
  }
  .main-row-2 h3 {
    margin-top: 20rem;
  }
  button {
    font-size: 0.875rem;
    padding: 0.5rem 1.5rem;
  }
}

@media (max-width: 318px) {
  .home {
    margin: 0 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 2rem;
  }
  .home img {
    display: none;
  }
  .main-row-2 h3 {
    margin-top: 2.5rem;
  }
}

footer {
  position: relative;
  background-color: #22205f;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 1fr;
      grid-template-columns: 2fr 1fr;
  grid-auto-rows: minmax(2.5rem, auto);
}

.footer-list {
  margin: 0;
  margin-left: 2.5rem;
  -ms-grid-column: 1;
  grid-column: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}

.footer-list li {
  list-style: none;
  font-family: 'Anonymous Pro', monospace;
  font-weight: 400;
  font-size: 1.125rem;
  letter-spacing: -0.02em;
  color: white;
  margin: 2.25rem 0;
}

.footer-list li::after {
  content: '';
  display: block;
  width: 0%;
  height: 3px;
  border-radius: 2px;
  background: #6acadd;
  -webkit-transition: width .3s;
  transition: width .3s;
}

.footer-list li:hover::after {
  width: 100%;
}

.copyright-div {
  margin: 0;
  margin-right: 2.5rem;
  -ms-grid-column: 2;
  grid-column: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.copyright {
  font-family: 'Anonymous Pro', monospace;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.apiculae-link {
  color: inherit;
  -webkit-transition: color 0.35s ease-in-out;
  transition: color 0.35s ease-in-out;
}

.apiculae-link:hover {
  color: white;
  -webkit-transition: color 0.35s ease-in;
  transition: color 0.35s ease-in;
}

@media (max-width: 825px) {
  footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .footer-list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 1rem 0;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
  .footer-list li {
    margin: 0.5rem 1.5rem;
  }
  .copyright-div {
    margin: 1rem;
  }
}

.react-about-wrapper {
  height: 100%;
}

.about-grid {
  min-height: 100%;
  margin: 0 2.5rem;
  padding: 5rem 0;
  background-color: white;
  font-family: 'Anonymous Pro', monospace;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr 1fr;
      grid-template-columns: 1fr 4fr 1fr;
  isolation: isolate;
}

.img-1-about {
  -ms-grid-column: 1;
  grid-column: 1;
  position: relative;
  top: 1.5rem;
  left: -2.5rem;
  width: 14vw;
  z-index: -1;
  max-width: 230px;
}

.img-2-about {
  -ms-grid-column: 3;
  grid-column: 3;
  position: absolute;
  top: 0;
  right: 0;
  width: 45vw;
  max-width: 750px;
}

.about-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 65vw;
}

.about-info {
  width: 75%;
  padding: 0;
  margin: 0;
}

.about-info h1 {
  text-align: left;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.about-info p {
  margin-bottom: 2rem;
}

.author-info {
  width: 100%;
}

.author-info h2 {
  text-align: left;
  margin: 1rem 0;
  margin-left: 0;
  isolation: isolate;
}

.author-info h2::after {
  content: "";
  position: relative;
  display: block;
  left: -1.5rem;
  top: -1.6rem;
  width: 1rem;
  height: 1rem;
  background-color: #e46625;
  border-radius: 50%;
}

@media (max-width: 1100px) {
  .author-info h2::after {
    width: 0.75rem;
    height: 0.75rem;
    left: -1.25rem;
    top: -1.25rem;
  }
}

@media (max-width: 952px) {
  .author-info h2::after {
    width: 0.5rem;
    height: 0.5rem;
    left: -1rem;
    top: -0.85rem;
  }
}

.author-info p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

@media (max-width: 770px) {
  .about-grid {
    -ms-grid-columns: 18vw 1fr;
        grid-template-columns: 18vw 1fr;
  }
}

@media (max-width: 770px) and (max-width: 600px) {
  .about-grid {
    -ms-grid-columns: 15vw 1fr;
        grid-template-columns: 15vw 1fr;
  }
}

@media (max-width: 770px) {
  .img-1-about {
    width: 20vw;
    top: 1rem;
  }
}

@media (max-width: 770px) and (max-width: 600px) {
  .img-1-about {
    width: 21vw;
  }
}

@media (max-width: 770px) and (max-width: 500px) {
  .img-1-about {
    width: 22.5vw;
  }
}

@media (max-width: 770px) {
  .img-2-about {
    height: 0;
  }
  .about-flex {
    width: 100%;
  }
  .about-info h1 {
    margin-top: 1.5rem;
  }
  .about-info, .author-info {
    width: auto;
    margin-right: 1rem;
  }
}

@media (max-width: 380px) {
  .about-grid {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    padding-bottom: 2rem;
  }
}

@media (max-width: 380px) and (max-width: 318px) {
  .about-grid {
    margin: 0 1.5rem;
  }
}

@media (max-width: 380px) {
  .about-grid section {
    margin-left: 1rem;
  }
  .about-grid h1 {
    text-align: center;
    margin-top: 0;
  }
  .about-grid h2 {
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }
  .about-grid img {
    display: none;
  }
  .about-grid p {
    margin-bottom: 0.5rem;
  }
}

.react-Rmodule-wrapper {
  height: inherit;
}

.Rmodule {
  min-height: 100%;
  margin: 0 2.5rem;
  padding: 5rem 0;
  background-color: white;
  font-family: 'Anonymous Pro', monospace;
  isolation: isolate;
}

.img-1-Rmodule {
  width: 30%;
  max-width: 430px;
  position: absolute;
  top: 8rem;
  left: 0;
}

.img-2-Rmodule {
  width: 25vw;
  max-width: 400px;
  position: relative;
  left: calc(100% - 25vw + 2.5rem);
}

.img-3-Rmodule {
  height: 0;
}

.Rmodule-info {
  width: 65%;
  margin: auto;
  margin-right: 2.5rem;
}

.Rmodule-info h1 {
  text-align: left;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.Rmodule-outline {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 2rem;
  width: auto;
  max-width: 100%;
  margin: 0 2.5rem;
  text-align: left;
}

.Rmodule-outline h2 {
  grid-column: span 2;
  margin: 0.5rem 0;
  text-align: center;
}

.Rmodule-outline div {
  padding-left: 1rem;
}

.Rmodule-outline div:first-of-type {
  -ms-grid-column: 1;
  grid-column: 1;
}

.Rmodule-outline div:last-child {
  -ms-grid-column: 2;
  grid-column: 2;
}

.Rmodule-outline h3 {
  font-size: 1.5rem;
  margin: 0;
  margin-top: -1rem;
}

.Rmodule-outline h3::before {
  content: "";
  position: relative;
  top: 1.15rem;
  left: -1rem;
  display: block;
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
  background-color: #3eba92;
}

.Rmodule-outline ol {
  list-style-type: upper-roman;
  font-family: 'Roboto', sans-serif;
  padding-left: 1.3rem;
}

@media (max-width: 1100px) {
  .img-2-Rmodule {
    width: 32vw;
    max-width: 400px;
    position: relative;
    left: calc(100% - 32vw + 2.5rem);
    top: -1.5rem;
    z-index: -1;
  }
  .Rmodule-outline {
    margin-top: -1.5rem;
  }
  .Rmodule-outline h3 {
    font-size: 1.25rem;
  }
  .Rmodule-outline h3::before {
    top: 0.95rem;
    left: -1rem;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
  }
}

@media (max-width: 952px) {
  .img-2-Rmodule {
    height: 0;
  }
  .Rmodule-outline {
    margin-top: 13%;
  }
}

@media (max-width: 770px) {
  .img-1-Rmodule {
    height: 0;
    width: 0;
  }
  .img-3-Rmodule {
    height: auto;
    width: 90%;
    position: relative;
    left: -2.5rem;
    top: 1.5rem;
  }
  .Rmodule-info {
    width: auto;
    margin: 0 2.5rem;
  }
  .Rmodule-info h1 {
    text-align: center;
  }
  .Rmodule-outline {
    gap: 0;
    margin-top: 0%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Rmodule-outline h2 {
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }
  .Rmodule-info, .Rmodule-outline {
    margin: 0 1rem;
  }
}

@media (max-width: 380px) {
  .Rmodule {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Rmodule h1 {
    margin-top: 1.5rem;
  }
  .Rmodule h3 {
    font-size: 1rem;
  }
  .Rmodule h3::before {
    top: 0.75rem;
    left: -1rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }
  .Rmodule ol {
    font-size: 0.85rem;
  }
  .img-3-Rmodule {
    top: 1rem;
  }
}

@media (max-width: 318px) {
  .Rmodule {
    margin: 0 1.5rem;
    padding-bottom: 2rem;
  }
  .Rmodule h1 {
    text-align: center;
    margin-top: 0;
  }
  .Rmodule h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .Rmodule img {
    display: none;
  }
  .Rmodule p {
    margin-bottom: 0.5rem;
  }
}

.react-blog-wrapper {
  height: inherit;
}

.blog {
  min-height: 100%;
  margin: 0 2.5rem;
  padding: 5rem 0;
  padding-bottom: 0;
  background-color: white;
  font-family: 'Anonymous Pro', monospace;
  isolation: isolate;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.blog h1 {
  text-align: center;
  margin: 2.5rem 1.5rem;
}

.img-1-blog {
  width: 60%;
  position: relative;
  bottom: 0;
  left: -2.5rem;
}

@media (max-width: 825px) {
  .img-1-blog {
    width: 100%;
  }
}

@media (max-width: 318px) {
  .blog {
    margin: 0 1.5rem;
    padding-bottom: 2rem;
  }
  .blog h1 {
    text-align: center;
    margin-top: 0;
  }
  .blog img {
    display: none;
  }
}
